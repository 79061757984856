*{
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.addTask{
  background-color: blue;
  padding: 25px;
}
  
.addTask input{
  margin: 0 5px;
  padding: 3px;
  background-color: white;
}

.addTask button{
  margin: 0 5px;
  padding: 3px;
  background-color: lightgreen;
  color:blue;
}
.addTask button:hover{
  background-color: blue;
  color: lightgreen;
}

.list{
  margin: 0 5px;
  padding: 3px;
}

.Task{
  margin: 10px;
  height: max-content;
  width: 350px;
}

.Task button{
  padding: 1px 3px;
  margin: 3px 10px;
}
